/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Amplify from 'aws-amplify'
import awsmobile from './src/aws-exports'

export const onClientEntry = () => {
   Amplify.configure(awsmobile);
}
