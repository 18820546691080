// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:8a465c02-09b8-48c1-89a6-2b08ddf7d350",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_KE17yI3K8",
    "aws_user_pools_web_client_id": "pck3i7kgu8p19ejfk4n1c8a0r",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://7rm4mdaekfe4lohnuh6dyrumze.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-noptnn3pufdkjgh6y6bc2t5gyu"
};


export default awsmobile;
